import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterLink, RouterOutlet } from "@angular/router";
import {TuiButton} from '@taiga-ui/core';

@Component({
  selector: 'pxc-sidebar',
  standalone: true,
  imports: [RouterLink, RouterOutlet, TuiButton],
  templateUrl: './sidebar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarComponent { 
  expanded:boolean=false;
}

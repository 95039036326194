import { Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { Title } from "@angular/platform-browser";
import { SidebarComponent } from "./core/menu/sidebar/sidebar.component";
import { HeaderComponent } from "./core/menu/header/header.component";
import { TuiRoot } from '@taiga-ui/core';

@Component({
  selector: 'pxc-root',
  standalone: true,
  imports: [RouterOutlet, SidebarComponent, HeaderComponent, TuiRoot],
  templateUrl: './app.component.html'
})
export class AppComponent {
  private readonly titleService = inject(Title);
    title = 'Backoffice';

    constructor () {
    this.titleService.setTitle(this.title);
  }
}

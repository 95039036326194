<aside
    class="w-40 h-full p-1 flex flex-col bg-white rounded-br-[1.5rem]"
>
    <h2 class="text-center m-1 primary">
        Tools
    </h2>
    <a
        routerLink="/ocr"
        tuiButton
        class="m-1"
    >
        OCR
    </a>
    <a
        routerLink="/forms"
        tuiButton
        class="m-1"
    >
        Form Builder
    </a>
</aside>